import Mousetrap from "mousetrap"
import { ready } from "#js/components/utils"

ready(function () {
  document.querySelectorAll(".mousetrap").forEach(function (element) {
    Object.entries(JSON.parse(element.textContent)).forEach(([key, value]) => {
      Mousetrap.bind(key, () => {
        const rootStyle = document.documentElement.style
        const currentLocation = new URL(globalThis.location.href)

        switch (key) {
          case "l u x":
          case "l u m o s":
            rootStyle.setProperty("color-scheme", "light")
            break
          case "n o x":
            rootStyle.setProperty("color-scheme", "dark")
            break
          case "/":
            document.getElementById(value).focus()
            break
          default:
            if (currentLocation.pathname !== value) {
              globalThis.location.href = value
            }
        }
        return false
      })
    })
  })
})
